import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ColorTheme1 } from "theme/chart";

const InflowOutflowChart = ({ data }) => {
  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new("inflow-outflow-chart");

    root.setThemes([ColorTheme1.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout
      })
    );

    chart.getNumberFormatter().set("numberFormat", "#.#s");

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 60,
          strokeOpacity: 0.1
        })
      })
    );

    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      min: 0,
      categoryField: "month",
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minorGridEnabled: true,
        minGridDistance: 20
      })
    }));

    yAxis.data.setAll(data);

    // Add series
    const createSeries = (field, labelCenterX, pointerOrientation, rangeValue) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          categoryYField: "month",
          sequencedInterpolation: true,
          clustered: false,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: pointerOrientation,
            labelText: "{categoryY}: {valueX}"
          })
        })
      );
        
      series.columns.template.setAll({
        height: am5.p100,
        strokeOpacity: 0,
        fillOpacity: field === "entries" ? 0.8 : 0.4
      });
        
      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: "{valueX}",
            populateText: true,
            centerX: labelCenterX
          })
        });
      });

      let rangeDataItem = xAxis.makeDataItem({
        value: rangeValue
      });
      xAxis.createAxisRange(rangeDataItem);
      rangeDataItem.get("grid").setAll({
        strokeOpacity: 1,
        stroke: series.get("stroke")
      });
    
      let label = rangeDataItem.get("label");
      label.setAll({
        text: field.toUpperCase(),
        fontSize: "1.1em",
        fill: series.get("stroke"),
        paddingTop: 10,
        isMeasured: false,
        centerX: labelCenterX
      });
      label.adapters.add("dy", function() {
        return -chart.plotContainer.height();
      });
        
      series.data.setAll(data);
    };
    
    createSeries("exits", am5.p100, "right", Math.min(...data.map(d => d.exits)));
    createSeries("entries", 0, "left", Math.max(...data.map(d => d.entries)));

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="inflow-outflow-chart" style={{ width: "100%", height: "500px" }} />;
};

export default InflowOutflowChart;
