import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useLazyGetInsightsGenieDataQuery } from "store/slices/insightsGenieSlice";
import { downloadFile } from "utils/downloadFile";

import JobStatusDialog from "../JobStatus/JobStatusDialog";

const DownloadExcel = () => {
  const [currentParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dialog, setDialog] = useState({ show: false, jobId: null });
  const [getDownloadUrlOrJobId] = useLazyGetInsightsGenieDataQuery();
  const domain = currentParams.get("domain");
  const division = currentParams.get("division");
  const dates = currentParams.get("dates")?.split(",") || [];
  const l2_org = currentParams.get("l2_org");
  const l3_org = currentParams.get("l3_org");
  const l4_org = currentParams.get("l4_org");
  const geo = currentParams.get("geo");
  const country = currentParams.get("country");

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      if (!domain || !division) {
        setIsLoading(false);
        return;
      }
      const response = await getDownloadUrlOrJobId({
        division,
        domain,
        dates,
        l2_org,
        l3_org,
        l4_org,
        geo, 
        country
      }).unwrap();

      if (response?.startsWith("http")) {
        const url = response;
        downloadFile(url);
      } else {
        // save job_id to localstorage
        // show a popup and setup polling

        const jobId = response;
        console.log(jobId);

        setDialog({ show: true, jobId: jobId });
      }

      setIsLoading(false);
    } catch (error) {
      console.error({ error });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box mt={2}>
        <LoadingButton
          disabled={!domain || !division || !dates.length}
          variant="outlined"
          onClick={handleDownload}
          loading={isLoading}
        >
          Generate Insights
        </LoadingButton>
      </Box>
      <JobStatusDialog 
        open={dialog.show} 
        jobId={dialog.jobId} 
        onClose={() => setDialog({ ...dialog, show: false })} 
      />
    </>
  );
};

export default DownloadExcel;
