import React, { useEffect } from "react";

import { Box, Card, CardContent, Grid, FormControlLabel, Checkbox } from "@mui/material";
import AttritionDateRange from "pages/Dashboards/InsightsGenie/DateRange/AttritionDateRange";
import DateRange from "pages/Dashboards/InsightsGenie/DateRange/DateRange";
import HeadCountDates from "pages/Dashboards/InsightsGenie/DateRange/HeadCountDates";
import DomainSelect from "pages/Dashboards/InsightsGenie/DomainSelect/DomainSelect";
import DownloadExcel from "pages/Dashboards/InsightsGenie/DownloadExcel/DownloadExcel";
import GeoSelectOptions from "pages/Dashboards/InsightsGenie/SelectOptions/GeoSelectOptions";
import SelectOptions from "pages/Dashboards/InsightsGenie/SelectOptions/SelectOptions";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetInsightsGenieQuery } from "store/slices/insightsGenieSlice";

const InsightGenie = () => {
  const { data, isLoading } = useGetInsightsGenieQuery();
  const [currentParams, setCurrentParams] = useSearchParams();
  const domain = currentParams.get("domain");
  const annualized = currentParams.get("annualized");
  const dateData = data?.[domain];

  useEffect(() => {
    // poll jobIds saved in localstorage
  }, []);

  if (isLoading) {
    return (
      <Box className="flex justify-center items-center" py={8} height="calc(100vh - 148px)">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Box height="calc(100vh - 200px)">
      <Card sx={{ border: "1px solid black", mt: 8 }}>
        <CardContent sx={{ p: 4 }}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <SelectOptions data={data} />
            </Grid>
            <Grid item md={12}>
              <DomainSelect domains={data.domains_updated} />
            </Grid>

            {dateData ? <Grid item md={6}><DateRange data={dateData} /> </Grid>: null}
            
            {domain === "Headcount" ?
              <Grid item md={6}>
                <HeadCountDates />
              </Grid>
              : null
            }

            {domain === "Attrition" ? <Grid item md={12}>
              <Box display="flex" gap={4} alignItems="center" flexDirection="row" my={2}>
                <AttritionDateRange />
                <FormControlLabel
                  key="attrition-annualized"
                  control={
                    <Checkbox
                      checked={annualized}
                      onChange={(event) => {
                        if (event.target.checked) {
                          console.log("checked");
                          currentParams.set("annualized", true);
                        } else {
                          console.log("unchecked");
                          currentParams.delete("annualized");
                        }
                        setCurrentParams(currentParams);
                      }}
                    />
                  }
                  label="Annualized"
                />
              </Box>
            </Grid> : null}

            {
              domain && domain !== "Employee Insights" ? <Grid item md={12}>
                <GeoSelectOptions data={data} />
              </Grid> : null
            }

          </Grid>
          <DownloadExcel />
        </CardContent>
      </Card>
    </Box>
  );
};

export default InsightGenie;
