import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ColorTheme4 } from "theme/chart";

const HeadcountChangeChart = ({ data }) => {
  useLayoutEffect(() => {
    // Create root element
    let root = am5.Root.new("hc-change-chart");

    root.setThemes([ColorTheme4.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout
      })
    );

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minGridDistance: 30,
      minorGridEnabled: true
    });

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "division",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    xRenderer.grid.template.setAll({
      location: 1
    });

    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15
    });

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
      })
    }));

    let yAxisLabel = am5.Label.new(root, {
      rotation: -90,
      text: "Current Headcount",
      centerY: am5.p50,
      dx: -40, // Adjust this value as needed
      dy: 300,
      paddingRight: 50
    });
    yAxis.children.push(yAxisLabel);
    yAxisLabel.set("zIndex", 1);   
    

    //yAxis.data.setAll(data);

    // Add series
    // Column series
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "current_headcount",
      categoryXField: "division"
    }));

    series.columns.template.setAll({
      tooltipText: "{categoryX}: {valueY}",
      width: am5.percent(90),
      tooltipY: 0
    });

    series.data.setAll(data);

    // Variance indicator series
    let series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "current_headcount",
      openValueYField: "previous_headcount",
      categoryXField: "division",
      fill: am5.color(0x555555),
      stroke: am5.color(0x555555)
    }));

    series2.columns.template.setAll({
      width: 1
    });

    series2.data.setAll(data);

    series2.bullets.push(function () {
      let label = am5.Label.new(root, {
        text: "{valueY}",
        fontWeight: "500",
        fill: am5.color(0x00cc00),
        centerY: am5.p100,
        centerX: am5.p50,
        populateText: true
      });

      // Modify text of the bullet with percent
      label.adapters.add("text", function (text, target) {
        let percent = getVariancePercent(target.dataItem);
        return percent ? percent + "%" : text;
      });

      // Set dynamic color of the bullet
      label.adapters.add("centerY", function (center, target) {
        return getVariancePercent(target.dataItem) < 0 ? 0 : center;
      });

      // Set dynamic color of the bullet
      label.adapters.add("fill", function (fill, target) {
        return getVariancePercent(target.dataItem) < 0 ? am5.color(0xcc0000) : fill;
      });

      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: label
      });
    });

    series2.bullets.push(function () {
      let arrow = am5.Graphics.new(root, {
        rotation: -90,
        centerX: am5.p50,
        centerY: am5.p50,
        dy: 3,
        fill: am5.color(0x555555),
        stroke: am5.color(0x555555),
        draw: function (display) {
          display.moveTo(0, -3);
          display.lineTo(8, 0);
          display.lineTo(0, 3);
          display.lineTo(0, -3);
        }
      });

      arrow.adapters.add("rotation", function (rotation, target) {
        return getVariancePercent(target.dataItem) < 0 ? 90 : rotation;
      });

      arrow.adapters.add("dy", function (dy, target) {
        return getVariancePercent(target.dataItem) < 0 ? -3 : dy;
      });

      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: arrow
      });
    });

    // Make stuff animate on load
    series.appear();
    chart.appear(1000, 100);

    function getVariancePercent(dataItem) {
      if (dataItem) {
        let value = dataItem.get("valueY");
        let openValue = dataItem.get("openValueY");
        let change = value - openValue;
        return openValue > 0 ? Math.round(change / openValue * 100) : 0;
      }
      return 0;
    }

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="hc-change-chart" style={{ width: "100%", height: "500px" }} />;
};

export default HeadcountChangeChart;
