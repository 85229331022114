
import React, { useEffect } from "react";

import { Box, Grid, Paper, Typography } from "@mui/material";
import JourneyCard from "components/LocationInsights/JourneyCard";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetExternalLocationInsightsQuery } from "store/slices/locationSlice";

import ExternalViewTable from "./ExternalViewTable/ExternalViewTable";
import Filters from "./Filters/Filters";
import { CompetitiveConcentration, CorporateIncomeTaxRate, EnglishProficiency, HiringDifficulty, Supply, SupplyDemandRatio } from "./LocationInsightsExternalDetail/Overview/Overview";
import { useJourney } from "../useGroupedMenus";

const LocationInsightsExternalView = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetExternalLocationInsightsQuery();
  const { externalData, isLoading } = useSelector((state) => state.location);
  const { prev, next } = useJourney();

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    if(params.geo && params.country && params.city && params.job_code) {
      getLocationDetail({ params });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  return (
    <Box 
      sx={{
        pb: 4, 
        pt: 4, 
        px: 4, 
        bgcolor: "#F6F6F6"
      }}
    >
      <Typography variant="h2" mb={4}>
        Market Overview <br/><p className="text-sm font-bold"> Insights enabled by TalentNeuron's Market Intelligence</p>
      </Typography>

      <Paper sx={{ mb: 1, flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Box p={4}>
          <Filters />
        </Box>
      </Paper>
      <Box sx={{ minHeight: "calc(100vh - 400px)" }}>
        <Grid container mt={4} spacing={2}>
          {isLoading ? (
            <Grid item md={12}>
              <Box className="flex items-center justify-center w-full">
                <BeatLoader color="#5C5470" />
              </Box>
            </Grid>
          ) : (
            externalData?.length === 1 ? (
              <>
                <Grid item xs={12} lg={6} xl={3}>
                  <Supply data={externalData[0].supply} />
                </Grid>
                <Grid item xs={12} lg={6} xl={3}>
                  <SupplyDemandRatio data={externalData[0].supplyDemandRatio} />
                </Grid>
                <Grid item xs={12} lg={6} xl={3}>
                  <HiringDifficulty data={externalData[0].hiringDifficulty} />
                </Grid>
                <Grid item xs={12} lg={6} xl={3}>
                  <CompetitiveConcentration data={externalData[0].competitiveConcentration} />
                </Grid>
                <Grid item xs={12} lg={6} xl={3}>
                  <CorporateIncomeTaxRate data={externalData[0].country_corporate_income_taxrate} />
                </Grid>
                <Grid item xs={12} lg={6} xl={3}>
                  <EnglishProficiency data={externalData[0].english_proficiency} />
                </Grid>
                <Grid item xs={12}>
                  <ExternalViewTable data={externalData[0].data_table} />
                </Grid>
              </>
            ) : (
              <Grid item md={12}>
                <Paper sx={{ p: 4 }}>
                  <Typography mt={4} variant="body1" textAlign="center" fontWeight="bold" fontSize="24px">
                    No data available. Try selecting or changing the location and job title filters
                  </Typography>
                </Paper>
              </Grid>
            )
          )}
          <Grid item xs={12}>
            {
              prev || next ? <JourneyCard prev={prev} next={next} /> : null
            }
          </Grid>   
        </Grid>
      </Box>
    </Box>
  );
};

export default LocationInsightsExternalView;
