import { useEffect, useRef, useState } from "react";

import { Dialog, DialogTitle, DialogContent, DialogContentText, LinearProgress, DialogActions, Button, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useLazyGetInsightsJobStatusQuery } from "store/slices/insightsGenieSlice";
import { downloadFile } from "utils/downloadFile";

const FETCH_STATE = {
  POLLING: "POLLING",
  ERROR: "ERROR"
};

const defaultState = {
  state: FETCH_STATE.POLLING,
  message: "Generating the insights you requested. Please don't close the browser window. Your download will start soon.",
};

const JobStatusDialog = ({ open = false, jobId, onClose }) => {
  const [getInsightsJobStatus] = useLazyGetInsightsJobStatusQuery();
  const [currentParams] = useSearchParams();
  const domain = currentParams.get("domain");
  const pollingRef = useRef(null);
  const [fetchState, setFetchState] = useState(() => defaultState);

  // polling logic
  useEffect(() => {
    setFetchState(defaultState);
    if (jobId && open) {
      const pollJobStatus = async () => {
        try {
          const response = await getInsightsJobStatus({ job_id: jobId }).unwrap();
          if (response?.startsWith("http")) {
            downloadFile(response);
            clearInterval(pollingRef.current);
            onClose && onClose();
          }
        } catch(error) {
          setFetchState({ 
            state : FETCH_STATE.ERROR, 
            message: "An error occurred while generating insights. Please try again" 
          });
          clearInterval(pollingRef.current);
        }
      };
      pollJobStatus();
      pollingRef.current = setInterval(() => {
        pollJobStatus();
      }, 5000);
    }
   
    return () => {
      clearInterval(pollingRef.current);
    };
  }, [jobId]);

  return (
    <Dialog open={open}>
      <DialogTitle>{domain} Insights Generation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography fontSize="large" className={fetchState.state === FETCH_STATE.ERROR ? "text-red-400" : ""}>{fetchState.message}</Typography>
          {fetchState.state === FETCH_STATE.POLLING ? <LinearProgress color="primary" className="mt-2" /> : null }
        </DialogContentText>
        <DialogActions>
          <Button disabled={fetchState.state !== FETCH_STATE.ERROR} onClick={() => {
            onClose && onClose();
          }}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default JobStatusDialog;
