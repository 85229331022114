import { ArrowBackOutlined } from "@mui/icons-material";
import { Button, Box } from "@mui/material";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

const JourneyCard = ({ prev, next }) => {
  return (<Box sx={{ p: 2, mt: 4, display: "flex", justifyContent: "space-between", alignItems: "center", border: "12px solid #f9f9f9f9", borderRadius: "32px" }}>
    <div className="w-[40%] text-4xl font-extrabold">
          Continue exploring your curated dashboards
    </div>
    <div className="flex-1 flex">
      {
        prev ? <div className="flex border p-4 flex-col justify-between w-[40%] ml-auto">
          <div className="mb-4">
            <span className="text-2xl ">{prev.title}</span>
            <span className="block pb-4 border-b">{prev.description}</span>
          </div>
        
          <Button 
            component={NavLink} 
            to={prev.to} 
            variant="text" 
            color="primary"
            className="w-fit float-right flex items-center gap-2"
          >
            <ArrowBackOutlined /> Previous
          </Button>
        </div> : null
      }

      {
        next ? <div className={clsx("flex border p-4 flex-col justify-between w-[40%]", 
          { 
            "ml-auto": !prev, 
            "ml-2": prev
          })}>
          <div className="mb-4">
            <span className="text-2xl ">{next.title}</span>
            <span className="block pb-4 border-b">{next.description}</span>
          </div>
          
          <Button 
            component={NavLink} 
            to={next.to} 
            variant="contained" 
            color="primary"
            className="w-fit float-right"
          >
          Next
          </Button>
        </div> : null
      }
    </div>
  </Box>);
};

export default JourneyCard;
