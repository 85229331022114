import React from "react";

import { Box, Paper } from "@mui/material";
import JourneyCard from "components/LocationInsights/JourneyCard";
import { Outlet } from "react-router-dom";

import Filters from "./Filters/Filters";
import { useJourney } from "../useGroupedMenus";

const InternalViewDetailLayout = () => {
  const { prev, next } = useJourney();
  console.log(prev, next);
  return (
    <Box px={4} py={3}>
      <Paper sx={{ px:4, py: 2 }}>
        <Filters />
      </Paper>

      <Paper sx={{ px:4, py: 2, mt: 3 }}>
        <Box>
          <Outlet />
        </Box>
      </Paper>
      {
        prev || next ? <JourneyCard prev={prev} next={next} /> : null
      }
    </Box>
  );
};

export default InternalViewDetailLayout;
