import React, { useEffect } from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSearchParams } from "react-router-dom";

const DomainSelect = ({ domains }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const [selectedDomain, setSelectedDomain] = React.useState(() => currentParams.get("domain") ?? domains[0].name);

  useEffect(() => {
    if (!selectedDomain) {
      currentParams.delete("domain");
    } else {
      currentParams.set("domain", selectedDomain);
    }
    setCurrentParams(currentParams);
  }, [currentParams, selectedDomain, setCurrentParams]);

  const handleClickDomain = (domain) => {
    setSelectedDomain(prev => prev === domain.name ? "" : domain.name);
  };

  return (
    <Box mt={2}>
      <Typography variant="h6">Domain</Typography>
      <Box className="flex flex-wrap" gap={2}>
        {domains.map((domain) => (
          <FormControlLabel
            key={domain.name}
            control={
              <Checkbox
                checked={domain.name === selectedDomain}
                onChange={() => handleClickDomain(domain)}
                disabled={(selectedDomain.length > 0 && domain.name !== selectedDomain) || !domain.is_active}
              />
            }
            label={domain.name}
          />
        ))}
      </Box>
    </Box>
  );
};

export default DomainSelect;
