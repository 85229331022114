import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSearchParams } from "react-router-dom";

const GeoSelectOptions = ({ data }) => {
  const [geo, setGeo] = useState(null);
  const [country, setCountry] = useState(null);
  const [currentParams, setCurrentParams] = useSearchParams();

  const geoParam = currentParams.get("geo");
  const countryParam = currentParams.get("country");
  
  useEffect(() => {
    if (geoParam) {
      setGeo(geo);
    }
    if (countryParam) {
      setCountry(countryParam);
    }
  }, [geoParam, countryParam]);

  const handleChange = (event) => {
    currentParams.set("geo", event.target.value);
    currentParams.delete("country");
    setCurrentParams(currentParams);
    setGeo(event.target.value);
    setCountry(null);
  };

  const handleChangeCountry = (event) => {
    currentParams.set("country", event.target.value);
    setCurrentParams(currentParams);
    setCountry(event.target.value);
  };
  

  const countryOptions = data?.country?.[geo] || [];
  

  return (
    <Box display="flex" gap={4}>
      <FormControl sx={{ width: "300px" }}>
        <InputLabel id="demo-simple-select-label">Geo</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={geo}
          label="Geo"
          onChange={handleChange}
        >
          {data.geo.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "400px" }}>
        <InputLabel id="demo-simple-select-label">Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={country}
          disabled={countryOptions.length === 0}
          label="Country"
          onChange={handleChangeCountry}
        >
          {countryOptions.map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default GeoSelectOptions;
